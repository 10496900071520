console.info('Three.js learning')

import * as THREE from 'three'

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

import './style.css'


const myCam = new THREE.PerspectiveCamera(70, window.innerWidth / window.innerHeight, 0.1, 1000)
myCam.position.set(0, 0, 6)
// myCam.rotation.x = Math.PI * -0.4
const myScene = new THREE.Scene()


const loader = new THREE.TextureLoader()

loader.load('img/2k_stars_milky_way.jpg', (bg) => {
  myScene.background = bg
})


let texture = new THREE.TextureLoader().load('img/2k_moon.jpg')

let textureTwo = new THREE.TextureLoader().load('img/2k_earth_daymap.jpg')

const sphere = new THREE.SphereGeometry(1, 24, 24)

const sphereTwo = new THREE.SphereGeometry(2, 24, 24)

const material = new THREE.MeshBasicMaterial( { map: texture } )

const trueMat = new THREE.MeshStandardMaterial({ map: texture })
const trueMatTwo = new THREE.MeshStandardMaterial({ map: textureTwo })

let moon = new THREE.Mesh(sphere, trueMat)
let earth = new THREE.Mesh(sphereTwo, trueMatTwo)
earth.position.set(0, 0, -3)
myScene.add(earth)
myScene.add(moon)

const myRender = new THREE.WebGLRenderer({ antialias: true })
myRender.setPixelRatio( window.devicePixelRatio )
myRender.setSize(window.innerWidth, window.innerHeight)
document.body.appendChild(myRender.domElement)

const light = new THREE.PointLight(0xffffff, 35, 30)
light.position.set(4, 3, 4)
myScene.add(light)

function animate() {

  requestAnimationFrame( animate )

  //planet.rotation.x += 0.005
  moon.rotation.y += 0.01
  moon.translateX(0.1)

  earth.rotation.y += 0.008

  myRender.render(myScene, myCam)
}

animate()

